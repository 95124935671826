import React, { useState } from 'react';

import SEO from '../components/seo';

import '../sass/main.scss';
import { FaArrowAltCircleLeft, FaSpinner } from 'react-icons/fa';
import { FiPhone, FiMail, FiGlobe, FiMap, FiMapPin } from 'react-icons/fi';


const ResumePage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMesssage] = useState("");
    const [status, setStatus] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false)

    let form; // ref to
    const handleSubmit = async e => {
        e.preventDefault();
        if (!(email && message && name && subject)) {
            setError(true);
            setStatus("Please fill in all the fields.")
            return;
        }
        setLoading(true)
        try {
            const resp = await fetch("https://getform.io/f/62375362-54cb-4824-9003-688cf9f762db", {
                method: "POST",
                body: JSON.stringify({ name, email, message, subject }),
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            })

            setStatus("Thank you for your valuable message!")
            setError(false)
        } catch (err) {
            setError(true)
            setStatus("Sorry, there seems to have been a problem. Please try again.")
        } finally {

            setLoading(false)
        }

    }

    const handleTextInput = func => e => {
        setStatus("")
        setError(false)
        func(e.target.value)
    }

    return (
        <main className="contact grid-x align-center">
            <SEO title="Contact Me" />
            <div className="contact-header cell small-12 medium-9 large-7 grid-x">
                <div className="cell small-1">
                    <span className="back-button" onClick={() => window.history.back()}>
                        <FaArrowAltCircleLeft />
                    </span>
                </div>
                <div className="cell small-10">
                    <h1 className="title">Contact</h1>
                </div>
                <div className="cell small-1"></div>
            </div>
            <div className="tagline cell small-12 medium-9 ">
                I'm always looking for fun people to collaborate with. Drop me a line!
        </div>
            <div className="contact-card cell small-12 medium-6 large-4">
                <div className="card">
                    <div className="info-item">
                        <span className="icon"><FiPhone /></span>
                        <p className="text">+91 9930092488</p>
                    </div>
                    <div className="info-item">
                        <span className="icon"><FiMail /></span>
                        <p className="text">joshuanazareth97@gmail.com</p>
                    </div>
                    <div className="info-item">
                        <span className="icon"><FiGlobe /></span>
                        <p className="text">www.joshuanazareth.com</p>
                    </div>
                    <div className="info-item">
                        <span className="icon"><FiMapPin /></span>
                        <p className="text">Mumbai, IN.</p>
                    </div>
                </div>
            </div>
            <div className="contact-form cell small-12 medium-6 large-4">
                <form className="grid-x grid-margin-x" action="https://getform.io/f/62375362-54cb-4824-9003-688cf9f762db" method="POST" target="_blank" id="ajaxForm" onSubmit={handleSubmit}>
                    <input type="text" placeholder="Name" name="name" value={name} onChange={handleTextInput(setName)} className="cell small-12 large-6" />
                    <input type="text" placeholder="Email" name="email" value={email} onChange={handleTextInput(setEmail)} className="cell small-12 large-6" />
                    <input type="text" placeholder="Subject" name="subject" vlaue={subject} onChange={handleTextInput(setSubject)} className="cell small-12" />
                    <textarea placeholder="Message" name="message" value={message} rows="3" onChange={handleTextInput(setMesssage)} className="cell small-12" />
                    <div className="cell small-12">
                        {loading ?
                            <FaSpinner />
                            :
                            <input type="submit" value="Send" onClick={handleSubmit} className="button" />
                        }
                    </div>
                    {status && <p className={`${error ? "error" : ""} status-text cell small-12`}>{status}</p>}
                </form>
            </div>
        </main>
    );
}

export default ResumePage;